import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueAwesomeSwiper from "vue-awesome-swiper";
// import ElementUI from "element-ui";
// import "element-ui/lib/theme-chalk/index.css";
import "./utils/rem.js";
import commen from "./utils/commen.js";
import Msg from "./utils/msg";
import Meta from "vue-meta";

// 生成海报组件
import VueCanvasPoster from "vue-canvas-poster";

Vue.config.productionTip = false;
// Vue.use(ElementUI);
Vue.use(commen);
Vue.use(Msg);
Vue.use(Meta);
Vue.use(VueCanvasPoster);
new Vue({
  router,
  store,
  VueAwesomeSwiper,
  render: (h) => h(App),
}).$mount("#app");
