import MsgComponent from "../components/yl-message.vue";

const Msg = {};

Msg.install = (Vue) => {
  const vue = Vue;
  const MsgClass = Vue.extend(MsgComponent);
  const instance = new MsgClass();
  instance.$mount(document.createElement("div"));
  document.body.appendChild(instance.$el);

  //   let timer;

  const MsgMain = {
    show_message(txt, type, closed) {
      //   clearTimeout(timer);
      //   timer = setTimeout(() => {
      instance.visible = false;
      //   }, 2000);
      instance.txt = txt;
      instance.type = type;
      instance.closed = closed;
      instance.visible = true;
    },

    success(txt, type = "success") {
      this.show_message(txt, type, closed);
    },

    error(txt, type = "error") {
      this.show_message(txt, type, closed);
    },
  };
  vue.prototype.$ylmessage = MsgMain;
};

export default Msg;
