import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    userId: "",
    url: "https://www.illumina.vh.sauos.com/shareLogo.png",
  },
  mutations: {
    SET_USERID: (state, id) => {
      state.userId = id;
    },
  },
  actions: {},
  modules: {},
});
