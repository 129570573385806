<template>
  <div class="model" v-if="visible">
    <div :class="['main', isMobile ? 'mobile' : 'pc']">
      <div class="icon" @click="close()"></div>
      <div class="img">
        <img v-if="type == 'success'" src="../assets/success.png" alt="" />
        <img v-if="type == 'error'" src="../assets/error.png" alt="" />
      </div>
      <div class="info">{{ txt }}</div>
      <div class="button">
        <button @click="close()">我知道了</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      txt: "",
      type: "",
      visible: false,
      isMobile: null,
      timeOut: null,
    };
  },
  watch: {
    visible(val) {
      // console.log(val);
      if (val) {
        this.timeOut = setTimeout(() => {
          this.visible = false;
        }, 1500);
      } else {
        clearTimeout(this.timeOut);
      }
    },
  },
  created() {
    this.isMobile = this.$isMobile();
  },
  methods: {
    close() {
      this.visible = false;
    },
    // confirm() {
    //   this.visible = false;
    //   this.$router.replace({path: '/'});
    // },
  },
};
</script>

<style lang="scss" scoped>
.model {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10010;
  width: 100%;
  height: 100%;
  background-color: rgba($color: #000000, $alpha: 0.2);
  .main {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: #fff;
    border-radius: 8px;
    .icon {
      cursor: pointer;
      position: absolute;
      background: url("../assets/close2.png") 0 0 no-repeat;
      background-size: 100% 100%;
    }
    .img {
      margin: 0 auto;
      img {
        width: 100%;
      }
    }
    .info {
      text-align: center;
      font-size: 14px;
      color: #000;
    }
    button {
      cursor: pointer;
      width: 100%;
      border: none;
      background-color: #49b67b;
      color: #fff;
    }
  }
  .mobile {
    width: 2.15rem;
    height: 1.63rem;
    .icon {
      top: 0.1rem;
      right: 0.1rem;
      width: 0.2rem;
      height: 0.2rem;
    }
    .img {
      margin-top: 0.35rem;
      width: 0.45rem;
      height: 0.45rem;
    }
    .info {
      margin-top: 0.1rem;
    }
    .button {
      padding: 0.2rem 0.16rem 0;
    }
    button {
      //   width: 200px;
      height: 26px;
      border-radius: 13px;
    }
  }
  .pc {
    width: 430px;
    height: 326px;
    .icon {
      top: 20px;
      right: 20px;
      width: 40px;
      height: 40px;
    }
    .img {
      margin-top: 70px;
      width: 90px;
      height: 90px;
    }
    .info {
      margin-top: 20px;
    }
    .button {
      padding: 40px 32px 0;
    }
    button {
      //   width: 400px;
      height: 52px;
      border-radius: 26px;
    }
  }
}
</style>
