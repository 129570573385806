import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push;
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const routes = [
  // 首页
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/home",
    name: "Home",
    component: () => import("../views/home/home.vue"),
  },
  // 首页查看更多
  {
    path: "/home/more",
    name: "HomeMore",
    component: () => import("../views/home/more/home-more.vue"),
  },
  // 注册页
  {
    path: "/register",
    name: "Register",
    component: () => import("../views/register/register.vue"),
  },
  // 频道列表页
  {
    path: "/channel/:id",
    name: "Channel",
    component: () => import("../views/channel/channel.vue"),
  },
  // 直播列表页
  {
    path: "/live",
    name: "Live",
    component: () => import("../views/live/live.vue"),
  },
  // 回顾列表页
  {
    path: "/review",
    name: "Review",
    component: () => import("../views/review/review.vue"),
  },
  // 回顾查看更多
  {
    path: "/review/more",
    name: "ReviewMore",
    component: () => import("../views/review/more/review-more.vue"),
  },
  // 专题查看更多
  {
    path: "/special",
    name: "SpecialMore",
    component: () => import("../views/special/special-list.vue"),
  },
  // 专题详情
  {
    path: "/special/:id",
    name: "Special",
    component: () => import("../views/special/special.vue"),
  },
  // 个人中心
  {
    path: "/personal",
    name: "Personal",
    component: () => import("../views/personal/personal.vue"),
  },
  // 基本信息
  {
    path: "/personal/information",
    name: "Information",
    component: () => import("../views/personal/personal-information.vue"),
  },
  // 历史记录
  {
    path: "/personal/history",
    name: "History",
    component: () => import("../views/personal/personal-history.vue"),
  },
  // 预约
  {
    path: "/personal/appointment",
    name: "Appointment",
    component: () => import("../views/personal/personal-appointment.vue"),
  },
  // 收藏
  {
    path: "/personal/collect",
    name: "Collect",
    component: () => import("../views/personal/personal-collect.vue"),
  },
  // 修改密码
  {
    path: "/personal/change",
    name: "Change",
    component: () => import("../views/personal/personal-change.vue"),
  },
  // 搜索页
  {
    path: "/search",
    name: "Search",
    component: () => import("../views/search/search.vue"),
  },
  // 登录页
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/login/login.vue"),
  },
  // 找回密码
  {
    path: "/retrieve",
    name: "Retrieve",
    component: () => import("../views/retrieve/retrieve.vue"),
  },
  // 微信授权页面
  {
    path: "/auth",
    name: "Auth",
    component: () => import("../views/auth/auth.vue"),
  },
  // 注册协议
  {
    path: "/clause",
    name: "Clause",
    component: () => import("../views/clause/clause.vue"),
  },
  // 直播间
  {
    path: "/watch/:id",
    name: "Watch",
    component: () => import("../views/watch/watch.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  // base: "/qwer007/",
  routes,
});

router.beforeEach((to, from, next) => {
  window.history.replaceState(null, null, window.location.href);
  next();
});

router.afterEach(() => {
  window.scrollTo(0, 0);
});

export default router;
