exports.install = function (Vue) {
  // 查询是否是移动端
  Vue.prototype.$isMobile = function () {
    if (document.body.clientWidth < 1200) {
      return true;
    } else {
      return false;
    }
  };
  // 微信授权
  Vue.prototype.$wxAuth = function () {
    var IsWeixin = test(/micromessenger/);
    sessionStorage.setItem("nowUrl", location.href);
    let wxUser = sessionStorage.getItem("wxUser");
    // console.log(1)
    // console.log(IsWeixin)
    if (!wxUser && IsWeixin) {
      let url = location.origin + process.env.BASE_URL + "auth";
      // location.replace(
      //   `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx48632b4a216d871c&redirect_uri=${url}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`
      // );
      location.replace(`https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx48632b4a216d871c&redirect_uri=${url}&response_type=code&scope=snsapi_userinfo&state=STATE&component_appid=wx8bed7d1ee9ffef20#wechat_redirect`)
    }
  };
  // 查询设备
  var WIN = window;
  var NA = WIN.navigator;
  var UA = NA.userAgent.toLowerCase();

  function test(needle) {
    return needle.test(UA);
  }

  Vue.prototype.$device = function () {
    var IsAndroid = test(/android|htc/) || /linux/i.test(NA.platform + "");
    var IsIPad = !IsAndroid && test(/ipad/);
    var IsIPhone = !IsAndroid && test(/ipod|iphone/);
    var IsIOS = IsIPad || IsIPhone;
    var IsWinPhone = test(/windows phone/);
    var IsWeixin = test(/micromessenger/);
    var IsPC = !IsAndroid && !IsIOS && !IsWinPhone;
    if (IsWeixin) {
      return "微信";
    } else if (IsPC) {
      return "PC";
    } else if (IsAndroid || IsIOS || IsWinPhone) {
      return "H5";
    } else {
      return "其他";
    }
  };
};
