<template>
  <router-view :key="key" />
</template>

<script>
export default {
  metaInfo: {
    meta: [
      {
        name: "viewport",
        content:
          "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0",
      },
    ],
  },
  computed: {
    key() {
      // 详情页面才更新
      if (this.$route.path.indexOf("watch") > 0) {
        return this.$route.path + Math.random();
      }
      return "";
    },
  },
};
</script>

<style lang="scss">
html,
body,
.page-view {
  height: 100%;
}
body {
  margin: 0;
  background-color: #f4f4f6;
  -webkit-tap-highlight-color: transparent;
  font-family: Arial, Helvetica, sans-serif;
}
img {
  // width: 100%;
  display: block;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  pointer-events: none;
}
.hidden1 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
// 两行文本溢出显示省略号
.hidden2 {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}
.hidden3 {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}
.hidden4 {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}
</style>
